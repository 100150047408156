import UI from '@ui/UI';
import {useTranslation, BA} from '@util/bachhelpers.js';
import {publicTiles} from '@util/static_tiles';
import {useDispatch, useSelector} from 'react-redux';
import {LANGUAGES} from '@util/consts';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

function Home(props) {
  const {_, i18n} = useTranslation();
  const dispatch = useDispatch();
  const redirectParams = useParams();
  const language = redirectParams.lang;

  const isapp = useSelector((state) => state.app);

  useEffect(() => {
    if ( typeof language !== 'undefined' ) {
      const newLang = language.toLowerCase() === LANGUAGES.EN ?
      LANGUAGES.EN : LANGUAGES.DE;
      i18n.changeLanguage(newLang);
      dispatch({
        type: 'set_language',
        language: newLang,
      });
    }
  }, [language]);

  const tiles = [];
  for ( const i in publicTiles ) {
    if ( i != 'auth' ) {
      tiles.push(i);
    }
  }

  /**
   * if isapp immediatly redirect to idp login
   */
  useEffect(() => {
    if (isapp !== '') {
      top.location.href = BA.BACHURL+'/idpapi/login';
    }
  }, [isapp]);

  return (<>
    {isapp !== ''?<></>:<div className="col-lg-5 col-xxl-3 p-0">
      <UI.InfoBox title={ _('app.welcome') }>
        <p>{_('app.welcome_login_info')}</p>
        <p>{_('app.welcome_not_a_student_info')}</p>

        <UI.BachTileGroup
          title=""
          tiles={publicTiles.auth}
        />
      </UI.InfoBox>
      <div className="featurebox p-4"> {
        tiles.map( (el, i)=><UI.BachTileGroup
          key={'tilegr_'+i}
          title={_('app.tilegroup.'+el)}
          className="mt-4 mb-4"
          tiles={publicTiles[el]}
        />,

        )
      }

      </div>
    </div>
    }</>);
}

export default Home;
