import {useTranslation} from '@util/bachhelpers.js';
import PropTypes from 'prop-types';
import {CloseButton, Col, Modal, Row} from 'react-bootstrap';

function CamModal(props) {
  const {_} = useTranslation();
  const {showCam, handleClose, callbackHandler} = props;

  return (
    <Modal show={showCam} onHide={handleClose}>
      <Modal.Header className="pt-2 pb-2">
        <Modal.Title>{_('app.app.camchoose')}</Modal.Title>
        <CloseButton onClick={handleClose} className="btn-close-white" />
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-2">
          <Col>
            <nav>
              <div className="row p-2">
                <button
                  className={`btn btn-small btn-secondary`}
                  onClick={() => {
                    callbackHandler('gallery');
                  }}
                >
                  {_('app.app.gallery')}
                </button>&nbsp;
                <button className={`btn btn-small btn-primary`}
                  onClick={() => {
                    callbackHandler('cam');
                  }}>{_('app.app.cam')}</button>

              </div>
            </nav>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
CamModal.propTypes = {
  showCam: PropTypes.bool,
  handleClose: PropTypes.func,
  callbackHandler: PropTypes.func,
  public: PropTypes.bool,
};
export default CamModal;
