import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Container} from 'react-bootstrap';

import {BA} from '@util/bachhelpers.js';

import Header from '@components/Header/Header';
import Footer from '@components/Footer/Footer';
import BackToTop from '@components/Partials/BackToTop';
import AppPublic from './AppPublic';
import AppMember from './AppMember';
import AppInit from './AppInit';

import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import BrowserDetector from '@components/Partials/BrowserDetector';

import ErrorBoundary from '@components/Error/ErrorBoundary';
import packageInfo from '@packageInfo';

function App(props) {
  const wrapperEl = useRef(null);
  const auth = useSelector((state) => state.auth);
  const isapp = useSelector((state) => state.app);

  useEffect(() => {
    if (auth) {
      BA.fixUserNameWidth(wrapperEl.current.offsetWidth);
      window.onresize = () => {
        BA.fixUserNameWidth(wrapperEl.current.offsetWidth);
      };
    } else {
      window.onresize = () => { };
    }
  }, [auth]);

  // if called URL ends with /app open <AppInit />
  if ( top.location.href.slice(-4) == '/app' ) {
    return (<AppInit />);
  } else {
    return (
      <Container
        id="wrapper"
        className={`mode_${isapp?isapp:'web'}`}
        ref={wrapperEl}
        fluid
      >
        <BrowserRouter basename={REACT_APP_BASE_URL}>
          <Header />
          <ErrorBoundary>
            <main id="main">
              <BrowserDetector />
              <div className={'row justify-content-md-end mx-0 ' +
                (process.env.REACT_APP_ENV == 'QA' ? 'row-qa-border' : '')}>
                {console.log(packageInfo.version)}
                <Routes>
                  <Route path="/member/*" element={<AppMember auth={auth} />} />
                  <Route path="/*" exact element={<AppPublic auth={auth} />} />
                </Routes>
              </div>
            </main>
          </ErrorBoundary>
          <Footer />
          <BackToTop />
        </BrowserRouter>
        {(process.env.REACT_APP_ENV === 'LOCAL' ||
    process.env.REACT_APP_ENV === 'DEV')&&<div id="debugconsole">
          <b className="debugcloser">DEBUGCONSOLE:</b>
        </div>}
      </Container>

    );
  }
}

export default App;
