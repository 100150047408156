/* eslint-disable camelcase */
/* eslint-disable max-len */
import {Form, Button, Row, Col, Table, Badge} from 'react-bootstrap';
import CamModal from '@components/App/CamModal';
import UI from '@ui/UI';
import {useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Box from '@components/Boxes/Box';
import './Profile.css';
import {useEffect} from 'react';
import {BA, useTranslation} from '@util/bachhelpers';
import findOrientation from 'exif-orientation';
import FormAccountPassword from '@components/Forms/FormAccountPassword';
import FormWLANPassword from '@components/Forms/FormWLANPassword';
import MyFavorites from '@components/Partials/MyFavorites';
import {URL_TYPES, LANGUAGES} from '@util/consts.js';
import {XLg} from 'react-bootstrap-icons';
import {useFormFields} from '@hooks/form-hook.js';

function Profile(props) {
  const {_} = useTranslation();
  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  // const [errorResp, setErrorResp] = useState('');
  const [xhrload, setXhrload] = useState(false);
  const [xhrDelete, setXhrDelete] = useState(false);
  const [inPageForm, setInPageForm] = useState('');
  const [file, setFile] = useState({attributes: {type: ''}, content: ''});
  const countries = useSelector((state) => state.countries);
  const userInfo = useSelector((state) => state.userInfo);
  const baseInfo = useSelector((state) => state.profileBaseInfo);
  const accountInfo = useSelector((state) => state.profileAccountInfo);
  const personalData = useSelector((state) => state.profilePersonalData);
  const studies = useSelector((state) => state.studies);
  const [fields, handleChange] = useFormFields([
    {requested_login: '', error: true},
  ]);
  const [toasts, setToasts] = useState([]);
  const mappingInfo = useSelector((state) => state.mappingInfo);

  // const recovery = useSelector(state => state.profileRecovery);
  const inputEl = useRef(null);
  const language = useSelector((state) => state.language);

  const isapp = useSelector((state) => state.app);

  {/*
  const REGISTRATION_CATEGORIES = {
    'I': _('app.profile.enrolled'),
    'N': _('app.profile.enrolled'),
    'X': _('app.profile.completed'),
    'Z': _('app.profile.closed'),
  }; */}

  const [showCam, setShowCam] = useState(false);
  const handleCloseCam = () => setShowCam(false);

  const handleInputChange = (e) => {
    const files = e.target.files;
    findOrientation(files[0], (err, orientation) => {
      console.html( JSON.stringify(orientation) );
      // setErrorResp('Rotation:' + orientation.rotate);
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        inputEl.current.setAttribute('disabled', 'disabled');
        setFile({
          attributes: files[0],
          content: e.target.result,
        });
      };
    });
  };

  const handleRemoveAvatar = (e) => {
    setXhrDelete(true);
    BA.ajax({
      bpoapi: true,
      method: 'post',
      url: BA.buildApiUrl(URL_TYPES.DELETE_AVATAR),
    }).then(async (response) => {
      console.log('Avatar deletion successful:', response);
      setXhrload(false);
      setXhrDelete(false);
      /* setFile({
            attributes: {type: ''},
            content: '',
        });*/
      inputEl.current.value = '';
      dispatch({
        type: 'set_userinfo',
        avatar: null,
        // Leave other fields in userInfo unchanged
      });
    }).catch((error) => {
      console.error('Avatar deletion failed:', error);
      setXhrload(false);
      setXhrDelete(false);
    });
  };

  const uploadAvatar = ()=>{
    BA.ajax({
      url: BA.buildApiUrl(URL_TYPES.UPLOAD_AVATAR),
      method: 'post',
      bpoapi: true,
      data: {file: file.content},
    }).then(async (response) => {
      if (response.data.path &&
        typeof response.data.path !== 'undefined' &&
        response.data.path.match(/^(files\/).+(jpg|jpeg|png|gif)$/ig)) {
        dispatch({
          type: 'set_userinfo',
          avatar: (response.data.path || response.data.path !== null) ?
            process.env.REACT_APP_BPOAPISERVER + '/' +
            response.data.path+'?d='+Date.now() : null,
        });
      }
      setFile({
        attributes: {type: ''},
        content: '',
      });
      inputEl.current.removeAttribute('disabled');
      return response.avatar;
    }).catch((e) => {
      dispatch({
        type: 'set_errcode',
        errcode: e,
      });
      setXhrload(false);
    });
  };

  useEffect(() => {
    if (file.attributes.size / (1024 * 1024) > 25) {
      // reject any file that is over 25mbs
      console.log('file too big');
      setFile({
        attributes: {type: ''},
        content: '',
      });
      inputEl.current.removeAttribute('disabled', 'disabled');
      setXhrload(false);
    } else if (file.attributes.type &&
      !(file.attributes.type == 'image/jpeg' ||
        file.attributes.type == 'image/png' ||
        file.attributes.type == 'image/gif')) {
      // reject file types that are not jpeg, png or gif images
      console.log('avatar is not an image file');
      setFile({
        attributes: {type: ''},
        content: '',
      });
      inputEl.current.removeAttribute('disabled', 'disabled');
      setXhrload(false);
    } else if (file.content !== '' &&
      // size limit for jpg and png is 25mb
      (((file.attributes.type == 'image/jpeg' ||
        file.attributes.type == 'image/png') &&
        file.attributes.size / (1024 * 1024) <= 25) ||
        // size limit for gif is 3mb
        (file.attributes.type == 'image/gif' &&
          file.attributes.size / (1024 * 1024) <= 3))

    ) {
      uploadAvatar();
    }
  }, [file.content]);

  useEffect(() => {
    if (countries.length === 0) {
      setXhrload(true);
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.COUNTRIES),
        bpoapi: true,
      }).then(async (response) => {
        setXhrload(false);
        if (response.data.countries.length > 0) {
          const countries = response.data.countries.map(({
            tid, code, bezeichnung_de, bezeichnung_en}) => ({
            countryId: tid,
            countryCode: code,
            countryName: bezeichnung_de,
            countryNameEn: bezeichnung_en,

          }));
          dispatch({
            type: 'set_countries',
            countries: countries,
          });
        }
      }).catch((e) => {
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  }, [countries]);

  const clickHandler = (e, formId) => {
    e.preventDefault();
    setInPageForm(formId);
  };

  useEffect(()=>{
    const user = BA.getTokenInfo();
    setUser(user);
  }, []);

  const handleClose = (i) => {
    const updatedToasts = toasts.map( (toast, ix)=>{
      if ( i === ix ) {
        toast.show = false;
      }
      return toast;
    });
    setToasts( updatedToasts );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Revert login mapping
    if (e.target === document.getElementById('revert')) {
      fields[0] = {
        requested_login: '',
        error: false,
      };
    }

    if ( !xhrload && fields.findIndex( (el) => el.error ) === -1 ) {
      const dataToSend = BA.sendData(fields);
      setXhrload(true);
      // API call
      BA.ajax({
        url: BA.buildApiUrl(URL_TYPES.USER_MAPPING),
        bpoapi: true,
        method: e.target.method,
        contentType: 'application/json',
        method: 'POST',
        data: JSON.stringify(dataToSend),
      }).then( (response) => {
        setXhrload(false);
        if ( response.error || response.status === 'error' ) {
          setToasts( [{
            variant: 'error',
            code: response.error ? response.error.code : response.code,
            message: response.error ?
          response.error.message : response.message,
            show: true}]);
        } else {
          window.location.reload(false);
        }
      }).catch((e)=>{
        dispatch({
          type: 'set_errcode',
          errcode: e,
        });
        setXhrload(false);
      });
    }
  };

  window.androidImageCallback = (str)=>{
    if ( str == 'cam-aborted' ) {
      console.html( 'CAM aborted');
    } else {
      setFile({
        attributes: {type: 'image/jpeg', size: 0},
        content: 'data:image/jpeg;base64,'+str,
      });
    }
  };

  const callbackHandler = (type)=>{
    handleCloseCam();
    if ( type == 'cam' ) {
      let mainwin = false;
      if (window.cordova_iab) mainwin = cordova_iab;
      else if (window.webkit && webkit.messageHandlers && webkit.messageHandlers.cordova_iab) mainwin = webkit.messageHandlers.cordova_iab;
      if (mainwin) {
        const stringifiedMessageObj = JSON.stringify({type: 'CAM', quality: 50, callback: 'androidImageCallback'});
        mainwin.postMessage(stringifiedMessageObj);
      } else {
        console.log( 'CAM not opening in browser' );
      }
    } else {
      console.log( 'open normal');
      setTimeout( ()=>{
        inputEl.current.click();
      }, 200);
    }
  };

  const avatarClickHandler = (e)=>{
    if ( isapp === 'android' || (isapp === 'ios' && process.env.REACT_APP_ENV == 'LOCAL')) {
      e.preventDefault();
      setShowCam(true);
    }
  };

  return (
    <Col className="p-0">
      {(isapp==='android'|| (isapp === 'ios' && process.env.REACT_APP_ENV == 'LOCAL'))&&<CamModal
        showCam={showCam}
        handleClose={handleCloseCam}
        callbackHandler={callbackHandler}
      />}
      <div className="infobox_wrapper">
        {!xhrload && <UI.InfoBox>
          <Row className="">
            <Col className="col-4 col-md-2 offset-lg-1 col-lg-2 col-xxl-1">
              <label
                htmlFor="avatarUpload"
                className="editImage"
                onClick={avatarClickHandler}
              >
                <div className="avatar-wrapper">
                  <UI.ImageElement
                    cover
                    src={userInfo.avatar}
                    alt="userimage" />
                </div>
                {(file.content !== '' || xhrDelete) &&
                  <UI.AjaxLoading notext />}
                <span className="icon bi-edit-w"></span>
              </label>
              <input
                type="file"
                accept="image/*"
                name="avatarUpload"
                id="avatarUpload"
                onChange={handleInputChange}
                ref={inputEl}
              />
              {(userInfo.avatar && typeof userInfo.avatar !== 'undefined') &&
                <XLg id="remove-image" onClick={handleRemoveAvatar} />}
            </Col>
            {!userInfo.wuniverseLinked && !userInfo.employee && (
              <Col className="col-8 col-md-4">
                <UI.InfoText>
                  {_('app.infostudent_text')}
                </UI.InfoText>
              </Col>
            )}
          </Row>
          {/* errorResp */}
        </UI.InfoBox>}
      </div>
      <Row className="p-4 g-0">
        <div className="col-12 offset-lg-1 col-lg-6 col-xxl-6 ">
          {inPageForm === '' && <div className="pb-4">
            <UI.PageTitle backurl="/member">
              {_('app.profile.title')}
            </UI.PageTitle>
          </div>}
          {xhrload && <UI.AjaxLoading />}
          {!xhrload && baseInfo && accountInfo && inPageForm === '' && <>


            {mappingInfo.isMappingVisible && (
              <>
                <div className="row m-0 datebox mb-3 mb-4 loginMappingBox">
                  <div className="col-12 pt-2">
                    <h2>{_('app.profile.map_title')}</h2>
                    {!mappingInfo.isUserMapped && (
                      <p className="mb-0">{_('app.profile.map_not_active')} </p>
                    )}
                  </div>
                  <div className="p-2">
                    {mappingInfo.isUserMapped && (
                      <div className="d-flex align-items-center mb-3">
                        <Badge bg="mainmedium me-2">
                          {_('app.profile.map_currently')}
                        </Badge>
                        <Col sm={6}>
                          <p className="mb-0">
                            <b>{baseInfo.loginName}</b>,{' '}
                            {baseInfo.firstName}{' '}
                            {baseInfo.lastName}, Tid: {baseInfo.id}
                          </p>
                        </Col>
                        <div className="ms-auto">
                          <Button
                            id="revert"
                            type="button"
                            className="px-4 mb-3 me-4"
                            variant="secondary"
                            onClick={handleSubmit}
                          >
                            {_('app.profile.map_reset')}
                          </Button>
                        </div>
                      </div>
                    )}
                    <Form
                      method="post"
                      action=""
                      autoComplete="off"
                      onSubmit={handleSubmit}
                      className="dataform"
                    >
                      {/* eslint-disable-next-line max-len */}
                      <UI.ToastMessage toasts={toasts} handleClose={handleClose} />
                      <div className="row align-items-center">
                        <div className="row align-items-center">
                          <Form.Label
                            htmlFor="requested_login" className="col-md-3 mb-0">
                            <span className="labeltext">
                              {_('app.profile.map_username')}:
                            </span>
                          </Form.Label>
                          <Col sm={6}>
                            <UI.FormControl
                              type="text"
                              validation
                              name="requested_login"
                              handler={handleChange}
                              className="ps-2"
                              autoComplete="off"
                              placeholder={_('app.profile.map_username_hint')}
                            />
                          </Col>
                          <Col sm={3} className="d-flex align-items-end
                           justify-content-end">
                            <Button type="submit"
                              className="px-4 mt-2 mt-sm-0"
                              variant="primary">
                              {_('app.profile.map_login')}
                            </Button>
                          </Col>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </>
            )}


            <Box
              type="course mb-4"
              title={userInfo.employee ? _('app.profile.employeeacc') :
                _('app.profile.studentacc')}
              variant="databox"
            >
              {userInfo.student &&
              <Badge bg="mainmedium me-2">{baseInfo.loginName}</Badge>
              }
              {baseInfo.prefixTitle ? baseInfo.prefixTitle + ' ' : ''}
              {baseInfo.firstName && baseInfo.lastName ?
              baseInfo.firstName + ' ' + baseInfo.lastName :
              'n.a.'}
              {baseInfo.suffixTitle ? ' ' + baseInfo.suffixTitle : ''}
              <br />

              {userInfo.student &&
                <h4 className='pt-2 mt-2'>{_('app.profile.studies')}</h4>

              }
              {userInfo.student && studies.map((study, i) => (
                <ul key={'stud_' + i} className="mt-1 ul-square">
                  <li
                    key={'stud_elem' + i}
                    // eslint-disable-next-line max-len
                    className={study.admissionCode === 'Z' ? 'closed-study' : ''}
                  >
                    {language === 'de' ? study.nameDe : study.nameEn}
                    {' (' + study.studyCode + ')'}
                    &nbsp;
                    {/*
                    {study.admissionCode !== 'Z' && (
                      <>
                        <Badge bg="highlight">
                          {study.rueckmeldung ? _
                          ('app.profile.rueckgemeldet')
                           : _('app.profile.nicht_rueckgemeldet')}
                        </Badge>
                        &nbsp;
                        <Badge bg="mainmedium">
                          {study.admissionCode
                           && REGISTRATION_CATEGORIES[study.admissionCode]}
                        </Badge>
                      </>
                    )}
                      */}
                  </li>
                </ul>
              ))}
            </Box>
            {!userInfo.wuniverse && <Box
              type="course mb-4"
              title={_('app.profile.accinfo')}
              variant="databox"
              clickHandler={(e) => {
                clickHandler(e, 'account');
              }}
            >
              <Table borderless>
                <tbody>
                  {/* <tr>
                    <th>{_('general.phone_number')}:</th>
                    <td>{user.phone}</td>
                  </tr>
                  <tr>
                    <th>{_('general.email')}:</th>
                    <td>{user.email}</td>
            </tr>*/}
                  <tr>
                    <th className="col-3">{_('general.password')}:</th>
                    <td>{'**********' /* user.password*/}</td>
                  </tr>
                  <tr>
                    <th className="col-3">{_('general.wlan')}:</th>
                    <td>{'**********' /* user.wlan_password*/}</td>
                  </tr>


                </tbody>
              </Table>
            </Box>}
            {userInfo.wuniverse && <Box
              type="course mb-4"
              title={_('app.profile.accinfo')}
              variant="databox"
              clickHandler={(e) => {
                clickHandler(e, 'wuniverse_account');
              }}
            >
              <Table borderless>
                <tbody>
                  <tr>
                    <th className="col-3">{_('general.password')}:</th>
                    <td>{'**********' }</td>
                  </tr>

                </tbody>
              </Table>
            </Box>}
            {!userInfo.wuniverse &&
            <Box
              type="course mb-4"
              title={_('app.profile.personaldata')}
              variant="databox"
              clickHandler={(e) => {
                clickHandler(e, 'personal', personalData);
              }}
            >
              <Table borderless>
                <tbody>
                  <tr>
                    <th className="col-3">{_('app.personal.country')}:</th>
                    <td>{language === LANGUAGES.DE ?
                      personalData.countryName :
                      personalData.countryNameEn}</td>
                  </tr>
                  <tr>
                    <th className="col-3">{_('app.personal.zipcode')}:</th>
                    <td>{personalData.postCode}</td>
                  </tr>
                  <tr>
                    <th className="col-3">{_('app.personal.city')}:</th>
                    <td>{personalData.city}</td>
                  </tr>
                  <tr>
                    <th className="col-3">{_('app.personal.address')}:</th>
                    <td>{personalData.address}</td>
                  </tr>
                  <tr>
                    <th className="col-3">{_('app.personal.co')}:</th>
                    <td>{personalData.co}</td>
                  </tr>
                </tbody>
              </Table>
            </Box>
            }
            {/*
            <Box
              type="course mb-4"
              title={_('app.profile.recovery')}
              variant="databox"
              clickHandler={(e) => {
                clickHandler(e, 'recovery');
              }}
            >
              <Table borderless>
                <tbody>
                  <tr>
                    <th>{_('general.email')}:</th>
                    <td>{user.recovery_email}</td>
                  </tr>
                  <tr>
                    <th>{_('general.phone_number')}:</th>
                    <td>{user.recovery_phone}</td>
                  </tr>

                </tbody>
              </Table>
            </Box>*/} </>}
          {!xhrload && inPageForm === 'account' && <>
            <div className="pb-4">
              <UI.PageTitle backurl="/" clickHandler={(e) => {
                clickHandler(e, '');
              }}>{_('app.profile.accinfo')}</UI.PageTitle>
            </div>
            {_('app.profile.password_info')}
            <h3 className="mt-4 pt-4">{_('app.profile.account_password')}</h3>
            <FormAccountPassword
              setUser={setUser}
              user={user}
              setInPageForm={setInPageForm}
              userInfo={userInfo}
            />
            <h3 className="mt-4 pt-4">{_('app.profile.wlan_password')}</h3>
            <FormWLANPassword
              setUser={setUser}
              user={user}
              setInPageForm={setInPageForm}
            />
          </>}
          {!xhrload && inPageForm === 'wuniverse_account' && <>
            <div className="pb-4">
              <UI.PageTitle backurl="/" clickHandler={(e) => {
                clickHandler(e, '');
              }}>{_('app.profile.accinfo')}</UI.PageTitle>
            </div>
            {_('app.profile.password_info')}
            <h3 className="mt-4 pt-4">{_('app.profile.account_password')}</h3>
            <FormAccountPassword
              setUser={setUser}
              user={user}
              setInPageForm={setInPageForm}
              userInfo={userInfo}
            />
          </>}
          {!xhrload && inPageForm === 'personal' && <>
            <div className="pb-4">
              <UI.PageTitle backurl="/" clickHandler={(e) => {
                clickHandler(e, '');
              }}>{_('app.profile.personaldata')}</UI.PageTitle>
            </div>
            <p>{userInfo.student ? _('app.profile.personaldata_change') :
              _('app.profile.personaldata_change_emp')
            }</p>
            <p>{userInfo.student ?
              <UI.BachLink href="https://short.wu.ac.at/oss">
                {_('navigation.oss.abbreviation')} ({_('navigation.oss.name')})
              </UI.BachLink> :
              <div />
            }
            </p>

            {/* <FormPersonalData
              address={user.address}
              apt_num_suite={user.apt_num_suite}
              city={user.city}
              zipcode={user.zipcode}
              country={user.country}
              setUser={setUser}
              user={user}
              setInPageForm={setInPageForm}
            />*/}
          </>}
          {/* !xhrload && inPageForm === 'recovery' && <>
            <div className="pb-4">
              <UI.PageTitle backurl="/" clickHandler={(e) => {
                clickHandler(e, '');
              }}>{_('app.profile.recovery')}</UI.PageTitle>
            </div>
            <FormRecovery
              recovery_email={user.recovery_email}
              recovery_phone={user.recovery_phone}
              setUser={setUser}
              user={user}
              setInPageForm={setInPageForm}
            />
            </>*/}</div>
        <div
          className="col-12 col-lg-3 offset-lg-1 col-xxl-3 d-none d-lg-block">
          <MyFavorites showedittile />
        </div>
      </Row>

    </Col>
  );
}
export default Profile;
