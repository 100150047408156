/* eslint-disable max-len */
/* eslint-disable camelcase */
// import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
// import MemberHome from '@member/pages/MemberHome';
import {useDispatch, useSelector} from 'react-redux';
import {BA} from '@util/bachhelpers.js';

function AppInit(props) {
  // const location = useLocation();
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const isapp = useSelector((state) => state.app);
  console.log( 'isapp in AppInit.jsx', isapp );

  useEffect(() => {
    // if /app was called and env REACT_APP_MOBILE is set true
    // check if page is open in inappbrowser => set_app
    if (process.env.REACT_APP_MOBILEAPP) {
      console.html(navigator.userAgent);
      let mainwin = false;
      if (window.cordova_iab) mainwin = cordova_iab;
      else if (window.webkit && webkit.messageHandlers && webkit.messageHandlers.cordova_iab) mainwin = webkit.messageHandlers.cordova_iab;
      if (mainwin) {
        const stringifiedMessageObj = JSON.stringify({type: 'SET_STORAGE', name: 'mywu_app', data: '1'});
        mainwin.postMessage(stringifiedMessageObj);
        dispatch({
          type: 'set_app',
          os: 'android',
        });
      } else {
        sessionStorage.setItem( 'mywu_app', '1' );
        dispatch({
          type: 'set_app',
          os: 'ios',
        });
      }
      top.location.href = BA.BACHURL+'/idpapi/loginapp';
    } else {
      top.location.href = '/';
    }
  }, []);

  return (
    <></>
  );
}

export default AppInit;
